header.banner {
  @extend .bg-primary;

  padding-top: $spacer;
  padding-bottom: $spacer;

  nav.navbar {
    @extend .p-0;
  }
}
