h1 {
  @extend .mb-0;
}

h2 {
  @extend .py-3, .text-primary;
}

h3 {
  @extend .mb-0;
}

body {
  position: relative;
}

.interface-selectors {
  @extend .my-3;
}

.querystring {
  @extend .bg-white, .py-3, .text-center;

  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.main-wrapper {
  position: relative;
}

.query-container {
  padding: $spacer $spacer*3;
  position: sticky;
  background-color: $white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  .query-string {
    @extend .text-center;

    width: 100%;
    display: block;
    word-wrap: break-word;
  }
}
